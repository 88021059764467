<template>
  <div class="row">
    <va-input
      class="flex mb-2 sm12 md6 lg3"
      placeholder="Filter..."
      v-model="filter"
    />
    <va-button
      class="ml-auto md3 add-btn"
      @click="this.addRbi()"
    >
      Add RBI
    </va-button>
  </div>

  <va-data-table
    :items="items"
    :columns="columns"
    :per-page="perPage"
    :current-page="currentPage"
    :filter="filter"
    clickable
    striped
    hoverable
    :loading="!(!!items.length)"
    @filtered="filtered = $event.items"
  >

    <template #cell(actions)="{ rowIndex }">
      <va-button flat icon="edit" @click="editItemById(rowIndex)"/>
      <va-button flat icon="delete" @click="displayModal(rowIndex)" />
      <va-button flat icon="eye" @click="viewItemById(rowIndex)"/>
    </template>

  </va-data-table>

  <div class="table--pagination">
    <va-pagination
      v-model="currentPage"
      input
      :pages="pages"
      v-if="!!items.length"
    />
  </div>

  <va-modal v-model="showModal" message="Are you sure?" @ok="deleteItemById(this.rowNumber)"/>
</template>

<script>
import { VaDataTable, VaPagination, VaInput, VaButton, VaModal } from "vuestic-ui";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RBI",
  components: {
    VaDataTable,
    VaPagination,
    VaInput,
    VaButton,
    VaModal
  },
  data() {
    const items = [];
    const columns = [
      {
        key: "house_id",
        sortable: true
      },
      {
        key: "created_at",
        sortable: true
      },
      {
        key: "updated_at",
        sortable: true
      },
      {
        key: "date_accomplished",
        sortable: true
      },
      {
        key: "address",
        sortable: true
      },
      {
        key: "family_name",
        sortable: false
      },
      {
        key: "street",
        sortable: true,
      },
      {
        key: "actions",
        width: 80,
      },
    ]

    return {
      items,
      columns,

      showModal: false,
      rowNumber: null,
      viewMode: false,
      editedItemId: null,
      editedItem: null,

      filter: "",
      filtered: null,

      perPage: 10,
      currentPage: 1,
    }
  },
  mounted() {
    this.fetchHouseList();
  },
  computed: {
    pages() {
      return (this.perPage && this.perPage !== 0)
        ? Math.ceil(this.houseCount / this.perPage)
        : this.filtered.length
    },
    ...mapGetters("rbi", {
      houseList: "getHouseList",
      houseCount: "getHouseCount",
    }),
  },
  methods: {
    addRbi() {
      this.$router.push({ name: "rbi-create" });
    },
    deleteItemById(id) {
      const rbiId = this.items[id].house_id;
      this.deleteHouseRecord(rbiId)
      this.items = [
        ...this.items.slice(0, id),
        ...this.items.slice(id + 1),
      ];
    },
    editItemById(id) {
      const rbiId = this.items[id].house_id;
      this.$router.push({ name: "rbi-edit", params: { id: rbiId } });
    },
    viewItemById(id) {
      const rbiId = this.items[id].house_id;
      this.$router.push({ name: "rbi-view", params: { id: rbiId } });
    },
    displayModal(id) {
      this.showModal = true;
      this.rowNumber = id;
    },
    ...mapActions("rbi", ["fetchHouseList", "deleteHouseRecord"]),
  },
  watch: {
    houseList: {
      handler(newValue) {
        if (newValue) {
          this.items = newValue;
        }
      },
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
.table--pagination {
  margin-top: 1rem;
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
}

.add-btn {
  margin: 20px;
}
</style>
